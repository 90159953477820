import { Tooltip, Popover } from './_bootstrap';
import Mmenu from 'mmenu-js';
import baguetteBox from 'baguettebox.js';

window.addEventListener('load', () => {
    initializeLightbox();
});

document.addEventListener('DOMContentLoaded', () => {
    initializeMmenu();
    initializeFadeIn();
    initializeTooltipsAndPopovers();
    copySidebarOnModal();
    initializeDropdownOverlay();
    centerActiveLink();
});

function initializeFadeIn() {
    const elementsFade = document.querySelectorAll('.transition-fade');
    elementsFade.forEach(element => {
        element.classList.add('fade-in');
    });
}

function initializePageFadeEffect() {
    // Einblenden der Seite beim Laden
    document.addEventListener('DOMContentLoaded', () => {
        const pageContent = document.querySelector('.page');
        if (pageContent) {
            pageContent.classList.add('fade-in');
        }
    });

    // Ausblenden der Seite beim Verlassen
    window.addEventListener('beforeunload', (event) => {
        const pageContent = document.querySelector('.page');
        if (pageContent) {
            pageContent.classList.add('fade-out');
        }

        // Verzögerung, um den Ausblendeffekt anzuzeigen
        setTimeout(() => {}, 1000); // Hier die Verzögerung anpassen, um den Ausblendeffekt zu steuern
    });
}

function initializeTooltipsAndPopovers() {
    document.querySelectorAll('[data-bs-toggle="tooltip"], [data-bs-toggle="popover"]').forEach(element => {
        if (element.getAttribute('data-bs-toggle') === 'tooltip') {
            new Tooltip(element);
        } else if (element.getAttribute('data-bs-toggle') === 'popover') {
            new Popover(element);
        }
    });
}

function initializeMmenu() {
    const mmenuElement = document.querySelector('#mmenu');
    if (mmenuElement) {
        new Mmenu('#mmenu', {
            slidingSubmenus: true,
            theme: 'light',
            setSelected: {
                hover: true,
                parent: true,
            },
            navbar: {
                add: true,
                title: 'MyPaX',
                titleLink: 'parent',
            },
            scrollBugFix: {
                fix: true
            },
            offCanvas: {
                position: 'left-front'
            },
            iconbar: {
                use: true,
                top: [
                    "<a href='/'><i class='fa fa-home'></i></a>",
                    "<a href='/akademie/seminare'><i class='fa-solid fa-user-graduate'></i></a>",
                    "<a href='/shop'><i class='fa-solid fa-cart-shopping'></i></a>",
                    "<a href='/service/zeichnungen'><i class='fa-solid fa-compass-drafting'></i></a>",
                    "<a href='/hotline'><i class='fa-solid fa-headset'></i></a>",
                    "<a href='/impressum'><i class='fa-solid fa-section'></i></a>",
                    "<a href='/datenschutz'><i class='fa-solid fa-shield-halved'></i></a>",
                ]
            },
            language: 'de',
            navbars: [
                {
                    position: 'top',
                    content: ['prev', 'breadcrumbs', 'close']
                }
            ]
        }, {
            offCanvas: {
                page: {
                    selector: '.dialog-off-canvas-main-canvas'
                }
            }
        });
    }
}

function copySidebarOnModal() {
    const sidebarContent = document.getElementById('sidebarContent');
    const modalSidebarContent = document.getElementById('modalSidebarContent');
    const sidebarRightModal = document.getElementById('sidebarRightModal');

    if (sidebarContent && modalSidebarContent && sidebarRightModal) {
        sidebarRightModal.addEventListener('show.bs.modal', () => {
            if (modalSidebarContent.innerHTML === '') {
                modalSidebarContent.innerHTML = sidebarContent.innerHTML;
            }
        });
    }
}

// BUGFIX Bootstrap modal appearing under backdrop
document.addEventListener('show.bs.modal', (event) => {
    const modal = event.target;
    document.body.appendChild(modal);
    const backdrop = document.querySelector('.modal-backdrop');
    if (backdrop) {
        modal.style.zIndex = parseInt(window.getComputedStyle(backdrop).zIndex, 10) + 1;
    }
    modal.focus();
});

function initializeLightbox() {
    // Muss im 'load' Event aufgerufen werden, damit die Bilder vollständig geladen sind
    baguetteBox.run('.baguetteBox', {
        captions: (element) => element.getElementsByTagName('img')[0].alt,
        overlayBackgroundColor: 'rgba(10, 18, 71, .75)'
    });
    baguetteBox.run('.baguetteBoxBlack', {
        captions: (element) => element.getElementsByTagName('img')[0].alt,
        overlayBackgroundColor: 'rgba(0, 0, 0, .9)'
    });
}

function initializeDropdownOverlay() {
    let el_overlay = null;
    document.body.addEventListener('shown.bs.dropdown', (event) => {
        if (!el_overlay) {
            el_overlay = document.createElement('span');
            el_overlay.className = 'screen-darken';
            const pageWrapper = document.querySelector('#page-wrapper');
            if (pageWrapper) {
                pageWrapper.appendChild(el_overlay);
            }
        }
    });
    document.body.addEventListener('hide.bs.dropdown', () => {
        if (el_overlay) {
            el_overlay.parentNode.removeChild(el_overlay);
            el_overlay = null;
        }
    });
}

function centerActiveLink() {
    const horizontalScrollNav = document.querySelectorAll('.scrolling-wrapper');
    
    horizontalScrollNav.forEach(wrapper => {
        wrapper.addEventListener('click', (event) => {
            const clickedElement = event.target.closest('a');
            if (clickedElement) {
                const navLinks = wrapper.querySelectorAll('a');
                navLinks.forEach(lnk => lnk.classList.remove('active', 'is_active'));
                clickedElement.classList.add('active', 'is_active');
                
                // Zentriere den aktiven Link beim Klick
                clickedElement.scrollIntoView({ behavior: 'smooth', inline: 'center', block: 'nearest' });
            }
        });
    });

    // Zentriere die aktiven Links beim Laden der Seite
    horizontalScrollNav.forEach(wrapper => {
        const activeLink = wrapper.querySelector('.active') || wrapper.querySelector('.is_active');
        if (activeLink) {
            activeLink.scrollIntoView({ behavior: 'smooth', inline: 'center', block: 'nearest' });
        }
    });
}

document.body.addEventListener('mousedown', (e) => {
    const target = e.target.closest('.btn, .ripple-init, .ripple-wrapper');
    if (target && target.tagName !== 'INPUT') {
        createRipple(e, target);
    }
});

function createRipple(event, target) {
    const ripple = document.createElement('div');
    ripple.classList.add('ripple');
    const rect = target.getBoundingClientRect();
    const size = Math.max(rect.width, rect.height);
    ripple.style.width = ripple.style.height = `${size}px`;
    ripple.style.left = `${event.clientX - rect.left - size / 2}px`;
    ripple.style.top = `${event.clientY - rect.top - size / 2}px`;
    target.appendChild(ripple);
    ripple.addEventListener('animationend', () => ripple.remove());
}